import { StorageService } from 'src/app/shared/services/storage.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/page/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private storageService: StorageService, private _authService: AuthService) { }

  canActivate() {
    if (this.storageService.getStorageItem('isLoggedin')) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }


}
