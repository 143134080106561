import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';


const routes: Routes = [
  {
    path: 'login',
    redirectTo: 'login',
  },
  { path: '',
    redirectTo: 'pages',
    pathMatch: 'full' },
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, { useHash: true });
