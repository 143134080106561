import { AuthInterceptor } from './interceptor/auth-interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { AppTranslationModule } from '../app.translation.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        FormsModule,
        AppTranslationModule,
        HttpClientModule,
        ReactiveFormsModule,
        AppTranslationModule,
        MaterialModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
    ],
    providers: [{
            provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true,
        }]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
