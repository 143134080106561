import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MENUS } from './page.menu';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit {
  public userName: string;
  public email: string;
  languages = [
    { code: 'en', name: 'English' },
  ];
  mobileQuery: MediaQueryList;
  fillerNav = MENUS;
  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private _router: Router, private storageService: StorageService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    let user = this.storageService.getStorageItem('user');
		this.userName = (user).firstname + ' ' + (user).lastname;
    this.email = (user).email;    
  }

  logout() {
    this.storageService.clear('isLoggedin');
    this.storageService.clear('user');
    this.storageService.clear('accessToken');
  }
}
