import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import decode from 'jwt-decode';
import { ApiService } from 'src/app/shared/services/api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private _apiService: ApiService, private _router: Router) { }

  /**
   * this is used to clear anything that needs to be removed
   */
  clear(): void {
    localStorage.clear();
  }

  /**
   * check for expiration and if token is still existing or not
   * @return {boolean}
   */
  isAuthenticated(): boolean {
    return localStorage.getItem('accessToken') !== null && !this.isTokenExpired();
  }

  // simulate jwt token is valid
  isTokenExpired(): boolean {
    return false;
  }

  /**
   * this is used to clear local storage and also the route to login
   */
  logout(): void {
    this.clear();
    this._router.navigate(['/login']);
  }
  decode() {
    return decode(localStorage.getItem('accessToken'));
  }

  login(credentials: any): Observable<any> {
    return this._apiService.post(environment.API_LOGIN, credentials);
  }
}
