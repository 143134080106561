import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { ApiService } from '../services/api.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(public storageService: StorageService, private _snackBar: MatSnackBar, private router: Router, private _apiService: ApiService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const accessToken = this.storageService.getStorageItem('accessToken');

    if (accessToken) {
      req = req.clone( { headers: req.headers.set('Authorization', `Bearer ${accessToken}`) } );
    }
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });

    return next.handle(req).pipe(
      tap(res => {
        if (res instanceof HttpResponse) { }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 400:
              this.openSnackBar(err.error.message, 'close');
              break;
            case 401:
              this.openSnackBar(err.error.message, 'close');
              break;
            case 403:
              this.openSnackBar(err.error.message, 'close');
              break;
            case 404:
              this.openSnackBar(err.error.message, 'close');
              break;
            case 422:
              if(err.error != undefined && err.error.message != undefined){
                this.openSnackBar(err.error.message, 'close');
              }
              break;
            case 500:
              this.openSnackBar(err.error.message, 'close');
              break;
            default:
              break;
          }
        }
        this._apiService.isLoggedInState.next('hide');
        return of(err);
      }));
  }

  logout() {
    this.storageService.clear('isLoggedin');
    this.storageService.clear('user');
    this.storageService.clear('accessToken');
    this.router.navigate(['/login']);
  }

  openSnackBar(message: string, action?: string, location?: string, panelClass = 'snackbar-error') {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
